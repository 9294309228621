import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ProvidersForm from "../components/providersForm";
import Title from "../components/title";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { FaRegWindowClose } from "react-icons/fa";
export const _frontmatter = {
  "title": "Providers",
  "calltoaction": "Apply to become part of our team",
  "description": "Are you a nurse, rehab therapist, or caregiver looking for more work in Southern California? Do you want flexible hours? We understand.",
  "tags": ["providers", "application", "apply", "rn", "cna", "lvn", "pt", "ot", "st", "caregiver", "jobs", "rehab", "staff"],
  "path": "/providers",
  "date": "2019-11-07T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ModalRoutingContext.Consumer>
  {({
        modal,
        closeTo
      }) => <div>
      {modal ? <>
        <Link to={closeTo} mdxType="Link">
            Close
        </Link>
        <Link to={closeTo} mdxType="Link">
          <FaRegWindowClose style={{
              float: `right`
            }} title="Close window" mdxType="FaRegWindowClose" />
        </Link>
        <div className="modal-header" style={{
            backgroundColor: `#9dce64`
          }}>
          <h1 className="modal-title">{props.pageContext.frontmatter.title}</h1>
          <p className="modal-description">{props.pageContext.frontmatter.description + " " + props.pageContext.frontmatter.calltoaction}.</p>
        </div>
        <ProvidersForm mdxType="ProvidersForm" /></> : <Layout mdxType="Layout">
          <Title title={props.pageContext.frontmatter.title} filename="hand-comfort.jpg" calltoaction={props.pageContext.frontmatter.calltoaction} mdxType="Title" />
          <SEO title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="SEO" />
          <div className="centered-content">
              <p className="form-description">{props.pageContext.frontmatter.description}</p>
              <ProvidersForm mdxType="ProvidersForm" />
          </div>
        </Layout>}
      </div>}
    </ModalRoutingContext.Consumer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      